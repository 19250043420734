import { computed, ref, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import moreProductsStoreModule
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/MoreProductsManagement/store/moreProductsStoreModule";

export default function useMoreProductsList() {
  const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
  const M_PRODUCTS_AP_STORE_MODULE_NAME = "ap-m-products-store";

  // Register modules
  if (!store.hasModule(MODULE_APP_STORE_NAME)) {
    store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
  }
  if (!store.hasModule(M_PRODUCTS_AP_STORE_MODULE_NAME)) {
    store.registerModule(M_PRODUCTS_AP_STORE_MODULE_NAME, moreProductsStoreModule);
  }

  const toast = useToast();
  const refMProductsListTable = ref(null);
  const loading = ref(false);
  const perPage = ref(10);
  const totalMProducts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(false);
  const recordDeleted = ref(false);

  const editTemplate = computed(
    () => store.state[MODULE_APP_STORE_NAME].editTemplate
  );

  const dataMeta = computed(() => {
    const localItemsCount = refMProductsListTable.value?.localItems.length || 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMProducts.value,
    };
  });

  const refetchData = () => refMProductsListTable.value?.refresh();

  const isSelected = (id) => {
    const { products } = editTemplate.value.templateData;
    return products.some((item) => item._id === id);
  };

  const selectMProducts = async (item) => {
    const updatedTemplate = {
      ...editTemplate.value,
      templateData: {
        ...editTemplate.value.templateData,
        products: (() => {
          const currentMProducts = editTemplate.value.templateData.products;
          const mProductsWithoutItem = currentMProducts.filter(p => p._id !== item._id);

          // Si las longitudes son iguales, el item no estaba en la lista, así que lo añadimos
          if (mProductsWithoutItem.length === currentMProducts.length) {
            return [...mProductsWithoutItem, item];
          } else {
            // Si las longitudes son diferentes, el item estaba en la lista y lo hemos removido
            return mProductsWithoutItem;
          }
        })()
      }
    };

    store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);

    try {
      await store.dispatch(`${MODULE_APP_STORE_NAME}/updateTemplate`, {
        template: updatedTemplate,
        toast,
        path: 'templateData.products'
      });
    } catch (error) {
      showErrorToast(toast, "Error updating template", axiosErrorHandle(error));
    }

    refMProductsListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, recordDeleted], refetchData);

  const fetchMoreProducts = (ctx, callback) => {
    loading.value = true;
    store
      .dispatch(`${M_PRODUCTS_AP_STORE_MODULE_NAME}/fetchMoreProducts`, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
        templateId: editTemplate.value._id,
      })
      .then(({ data: { products, total } }) => {
        console.log(products)
        callback(products);
        totalMProducts.value = total;
      })
      .catch((error) => {
        showErrorToast(
          toast,
          "Error fetching more products list",
          axiosErrorHandle(error)
        );
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    isSelected,
    fetchMoreProducts,
    perPage,
    currentPage,
    totalMProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMProductsListTable,
    refetchData,
    recordDeleted,
    loading,
    selectMProducts,
  };
}
