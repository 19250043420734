import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import { computed, ref, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";

export default function useHeadersList() {
  const MODULE_AP_STORE_NAME = "ap-whitelabel-templates";

  // Register module
  if (!store.hasModule(MODULE_AP_STORE_NAME))
    store.registerModule(MODULE_AP_STORE_NAME, whitelabelTemplatesModule);

  const toast = useToast();
  const refHeaderListTable = ref(null);
  const loading = ref(false);
  const perPage = ref(10);
  const totalHeaders = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("_id");
  const isSortDirDesc = ref(false);
  const recordDeleted = ref(false);

  const editTemplate = computed(
    () => store.state["ap-whitelabel-templates"].editTemplate
  );

  const dataMeta = computed(() => {
    const localItemsCount = refHeaderListTable.value?.localItems.length || 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalHeaders.value,
    };
  });

  const refetchData = () => {
    refHeaderListTable.value?.refresh();
  };

  const isSelected = (id, data) => {
    console.log(editTemplate.value.templateData?.header)
   return  editTemplate.value.templateData?.header._id === id
  };

  const findSelected = (id) => {
    return (
      refHeaderListTable.value?.localItems.find((item) => item._id === id) ||
      null
    );
  };

  const selectHeader = async (id) => {
    const selected = findSelected(id);

    if (!selected) return;

    const updatedTemplate = {
      ...editTemplate.value,
      templateData: {
        ...editTemplate.value.templateData,
        header: selected
      }
    };
    store.commit("kb-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);

    try {
      await store.dispatch("ap-whitelabel-templates/updateTemplate", {
        template: updatedTemplate,
        toast,
        path: 'templateData.header'
      });
    } catch (error) {
      showErrorToast(toast, "Error updating template", axiosErrorHandle(error));
    }
  };

  const fetchHeaders = (ctx, callback) => {
    loading.value = true;

    store
      .dispatch("ap-header/fetchHeaders", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
        templateId: editTemplate.value._id,
      })
      .then((response) => {
        loading.value = false;
        const { headers, total } = response.data;
        callback(headers);
        totalHeaders.value = total;
      })
      .catch((error) => {
        loading.value = false;
        showErrorToast(
          toast,
          "Error fetching headers list",
          axiosErrorHandle(error)
        );
      });
  };

  watch([currentPage, perPage, searchQuery, recordDeleted], () => {
    refetchData();
  });

  return {
    isSelected,
    fetchHeaders,
    perPage,
    currentPage,
    totalHeaders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refHeaderListTable,
    refetchData,
    recordDeleted,
    loading,
    selectHeader,
  };
}
